<template>
  <v-container class="fill-height align-start" :class="signinPageBgColor" fluid>
    <v-row>
      <v-col cols="10" />
      <v-col class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
              rounded
              color="white"
              :style="{ 'background-color': '#4Ba483 !important' }"
            >
              <v-icon left>mdi-web</v-icon>
              {{ languages[$i18n.locale] }}
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(language, key) in languages"
              :key="key"
              @click="localeItemClicked(key)"
              rel="noopener"
              ripple="ripple"
            >
              <v-list-item-title>{{ language }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        md="8"
        sm="10"
        style="max-width: 456px"
        class="text-center"
      >
        <v-row justify="center">
          <v-col cols="12">
            <v-icon
              size="96px"
              color="#bdbdbd"
              :style="{ 'background-color': 'white', 'border-radius': '100px' }"
            >
              mdi-account-circle
            </v-icon>
          </v-col>
          <v-col cols="12">
            <span class="title white--text">{{ $t("login_form") }}</span>
          </v-col>
          <v-col cols="12">
            <form @submit.prevent="login">
              <v-text-field
                :label="$t('account')"
                name="username"
                autocomplete="username"
                prepend-inner-icon="mdi-account"
                type="text"
                v-model="username"
                rounded
                filled
                background-color="white"
              />
              <v-text-field
                :label="$t('password')"
                id="password"
                name="password"
                autocomplete="current-password"
                prepend-inner-icon="mdi-lock"
                type="password"
                v-model="password"
                rounded
                filled
                background-color="white"
              />
              <div class="d-flex justify-content-center">
                <vue-recaptcha
                  ref="recaptcha"
                  class="mx-auto"
                  :sitekey="siteKey"
                  recaptchaHost="www.recaptcha.net"
                  :loadRecaptchaScript="true"
                  @verify="onCaptchaVerified"
                  @expired="onCaptchaExpired"
                />
              </div>
              <v-btn
                color="#424242"
                type="submit"
                rounded
                block
                :loading="busy"
                class="white--text"
                x-large
                :style="{ 'margin-top': '48px', 'margin-bottom': '64px' }"
              >
                {{ $t("sign_in") }}
              </v-btn>
            </form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
import * as supportedLocales from "../plugins/i18n/supported-locales";

export default {
  name: "Signin",
  middleware: "guest",
  components: { VueRecaptcha },

  mounted() {
    // this.fetch();
  },
  data() {
    return {
      languages: supportedLocales.locales,
      siteKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
      busy: false,
      username: "",
      password: "",
      recaptcha: "",
      remember: false,
    };
  },
  computed: {
    signinPageBgColor() {
      return {
        "signin-page-gradient-background":
          process.env.VUE_APP_LOGIN_PAGE_BG === "dynamic",
        "signin-page-static-background":
          process.env.VUE_APP_LOGIN_PAGE_BG === "static" ||
          !process.env.VUE_APP_LOGIN_PAGE_BG,
      };
    },
  },
  methods: {
    localeItemClicked(locale) {
      if (this.$i18n.locale !== locale) {
        // loadMessages(locale)
        this.$store.dispatch("lang/setLocale", locale);
      }
    },
    onCaptchaVerified(response) {
      this.recaptcha = response;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    login() {
      this.busy = true;
      const _this = this;
      axios
        .post("/app/api/login", {
          username: this.username,
          password: this.password,
          recaptcha: this.recaptcha,
        })
        .then(function (response) {
          // Update user.
          _this.$store.dispatch("auth/fetchUser");
          // _this.$store.dispatch('auth/updateUser', {
          //   user: response.data.data,
          //   // remember: this.remember
          // });
          _this.$store
            .dispatch("snackbar/openSnackbar", {
              message: response.data.message,
              color: "success",
            })
            .then(function () {
              // Redirect home.
              _this.$store.dispatch("auth/updateSafetyDialog", true);
            })
            .then(function () {
              // Redirect home.
              _this.$router.push({ name: "home" });
            });

          // // Save the token.
          // _this.$store.dispatch('auth/saveToken', {
          //   token: response.data.token,
          //   // remember: this.remember
          // });
          // // Fetch the user.
          // _this.$store.dispatch('auth/fetchUser');
        })
        .catch(function (error) {
          _this.$store.dispatch("snackbar/openSnackbar", {
            message: error.response.data.message,
            color: "error",
          });
        })
        .finally(function () {
          _this.$refs.recaptcha.reset();
          _this.busy = false;
        });
    },
  },
};
</script>

<style>
.signin-page-gradient-background {
  background-image: linear-gradient(
    to right top,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );
}
.signin-page-static-background {
  background-color: lightslategray;
}
</style>
